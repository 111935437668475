import type { ActionFunction } from '@remix-run/node';
import type { HomePinToggleMutationVariables } from 'generated/graphql';
import { toggleHomePin } from '~/modules/home/api/toggle-home-pin.server';
import { z } from 'zod';
import * as zfd from 'zod-form-data';
import { UserErrorResponse } from '~/modules/root/graphql/responses';

const ToggleHomePinInputSchema = z.object({
    itemID: zfd.numeric(z.number()),
    pin: z.string(),
});

export const action: ActionFunction = async ({ request, context }) => {
    const formData = await request.formData();
    const zfdFormData = zfd.formData(ToggleHomePinInputSchema);
    const zfdResult = zfdFormData.safeParse(formData);
    if (!zfdResult.success) {
        throw new UserErrorResponse(zfdResult.error);
    }
    const variables: HomePinToggleMutationVariables = {
        itemID: zfdResult.data.itemID,
        pin: zfdResult.data.pin === 'true',
    };

    return await toggleHomePin({
        ...context,
        ...variables,
    });
};

export const TOGGLE_HOME_PIN = '/remix-api/home/toggle-home-pin';
