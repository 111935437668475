import type { GrantedPermissions } from '~/modules/user/type';
import type { HomeArticleTileProps, HomeTileViewModel } from '../type';

export const canHomePin = (rootGrants: GrantedPermissions): boolean => {
    return rootGrants.editAdvertAdmin && rootGrants.editProfile;
};

export const mergeFeaturedProducts = <T>(
    pinnedItems: HomeTileViewModel<T>[],
    defaultItems: HomeTileViewModel<T>[],
    limit: number,
    adsCount: number
) => {
    const items: HomeTileViewModel<T>[] = [];
    const ids = new Set();
    // filter out duplicates
    pinnedItems.forEach((item) => {
        if (ids.has(item.id)) {
            return;
        }
        items.push(item);
        ids.add(item.id);
    });
    defaultItems.forEach((item) => {
        if (ids.has(item.id)) {
            return;
        }
        items.push(item);
        ids.add(item.id);
    });
    const res = items.slice(0, limit);
    const len = res.length;
    if (adsCount === 0 || adsCount >= len) {
        return res;
    }

    // We will replace the first-adsCount items with ads.
    // So we need to move some tail items to the beginning of the array as placeholders.
    const resWithAdsPlaceholders = res.slice(-adsCount);
    const resForShow = res.slice(0, len - adsCount);
    return resWithAdsPlaceholders.concat(resForShow);
};

export const mergeFeaturedItems = <T>(
    pinnedItems: HomeTileViewModel<T>[],
    defaultItems: HomeTileViewModel<T>[],
    limit: number
) => {
    const res: HomeTileViewModel<T>[] = [];
    const ids = new Set();
    // filter out duplicates
    pinnedItems.forEach((item) => {
        if (ids.has(item.id)) {
            return;
        }
        res.push(item);
        ids.add(item.id);
    });
    defaultItems.forEach((item) => {
        if (ids.has(item.id)) {
            return;
        }
        res.push(item);
        ids.add(item.id);
    });
    return res.slice(0, limit);
};

export const mergeFeaturedArticles = (
    pinnedArticles: HomeArticleTileProps[],
    defaultArticles: HomeArticleTileProps[],
    limit: number
) => {
    const res: HomeArticleTileProps[] = [];
    const ids = new Set();
    // filter out duplicates
    pinnedArticles.forEach((item) => {
        if (ids.has(item.tile.ID)) {
            return;
        }
        res.push(item);
        ids.add(item.tile.ID);
    });
    defaultArticles.forEach((item) => {
        if (ids.has(item.tile.ID)) {
            return;
        }
        res.push(item);
        ids.add(item.tile.ID);
    });
    return res.slice(0, limit);
};
