import type { AppDisplayModeType, HotButtonProps } from '@archipro-design/aria';
import type { SyntheticEvent } from 'react';
import type { Directory } from '../type';
import { Link } from '@remix-run/react';
import type { RemixLinkProps } from '@remix-run/react/dist/components';

type HotButtonPropsWithLink = HotButtonProps & Partial<RemixLinkProps>;

interface Props<T> {
    item: T;
    itemType: Directory;
    appDisplayMode: AppDisplayModeType;
    canEditAdmin: boolean;
    canEdit: boolean;
    editTooltip?: string;
    pinned?: boolean;
    onEditClick?: (item: T, sameTab?: boolean) => void;
    onEditLink?: string;
    repositionThumbLink?: string;
    editOnSameTab?: boolean;
    onDeleteClick?: (item: T) => void;
    onUnlinkItemClick?: (item: T) => void;
    onRepositionClick?: (item: T) => void;
    onPinClick?: (item: T) => void;
}

export const getHotButtons = <T>({
    item,
    itemType,
    appDisplayMode,
    canEditAdmin,
    canEdit,
    pinned,
    onEditClick,
    editTooltip,
    onEditLink,
    repositionThumbLink,
    editOnSameTab,
    onDeleteClick,
    onUnlinkItemClick,
    onRepositionClick,
    onPinClick,
}: Props<T>) => {
    if (!appDisplayMode.desktop) {
        // desktop only
        return undefined;
    }
    const hotButtonItems: HotButtonPropsWithLink[] = [];

    const hasPermission = canEditAdmin || canEdit;
    const hasEdit = onEditLink || onEditClick;

    if (onPinClick) {
        hotButtonItems.push({
            type: 'pin',
            toggled: pinned,
            tooltip: {},
            onClick: (e: SyntheticEvent) => {
                e.preventDefault();
                onPinClick(item);
            },
        });
    }

    if (hasPermission && hasEdit) {
        hotButtonItems.push({
            type: 'edit',
            tooltip: {
                content: {
                    children: editTooltip ?? `Edit ${itemType}`,
                },
            },
            ...(onEditLink
                ? {
                      as: Link,
                      to: onEditLink,
                      target: editOnSameTab ? '_parent' : '_blank',
                  }
                : {
                      onClick: (e: SyntheticEvent) => {
                          e.preventDefault();
                          e.stopPropagation();
                          onEditClick?.(item, editOnSameTab);
                      },
                  }),
        });
        if (onRepositionClick || repositionThumbLink) {
            hotButtonItems.push({
                type: 'reposition',
                tooltip: {
                    content: {
                        children: `Reposition image`,
                    },
                },
                ...(repositionThumbLink
                    ? {
                          as: Link,
                          to: repositionThumbLink,
                          target: editOnSameTab ? '_parent' : '_blank',
                          onClick: (e: SyntheticEvent) => {
                              e.stopPropagation();
                          },
                      }
                    : {
                          onClick: (e: SyntheticEvent) => {
                              e.preventDefault();
                              e.stopPropagation();
                              onRepositionClick && onRepositionClick(item);
                          },
                      }),
            });
        }
    }

    if (canEditAdmin) {
        if (onDeleteClick) {
            hotButtonItems.push({
                type: 'delete',
                tooltip: {},
                onClick: (e: SyntheticEvent) => {
                    e.preventDefault();
                    onDeleteClick(item);
                },
            });
        }

        if (onUnlinkItemClick) {
            hotButtonItems.push({
                type: 'unlink',
                tooltip: {},
                onClick: (e: SyntheticEvent) => {
                    e.preventDefault();
                    onUnlinkItemClick(item);
                },
            });
        }
    }

    return hotButtonItems.length > 0 ? hotButtonItems : undefined;
};
